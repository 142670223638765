import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";
import { RouteComponentProps } from "react-router";
import withTracker from "components/common/GoogleAnalytics";
import LoadingOverlap from "components/LoadingOverlap";
import { ROUTES } from "businessLogic/routes.constants";
import LoggedInPage from "./LoggedInPage";
import {
  Permissions,
  USER_PERMISSIONS,
} from "businessLogic/helpers/Permissions";
import { Error403 } from "pages/ErrorPages";
import { UserDetails } from "../../businessLogic/model";
import MainPage from "pages/RuleEngine/MainPage";

// App routes
const AsyncLoginPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Login" */ "pages/Auth/Login"
    )
);
const AsyncResetPassword = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "ResetPassword" */ "pages/Auth/ResetPassword"
    )
);
const AsyncActiveResetPassword = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "NewPassword" */ "pages/Auth/ResetPassword/SetNewPassword"
    )
);
const AsyncError404 = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Error404" */ "pages/ErrorPages"
    )
);
const AsyncUploadDocumentsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/UploadDocuments"
    )
);
const AsyncLabResultsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/LabResults"
    )
);

const AsyncUploadParametersPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/UploadParameters"
    )
);

const AsyncUploadHospitalReportsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/UploadHospitalReports"
    )
);

const AsyncReportsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Reports" */ "pages/Reports"
    )
);

const AsyncHospitalReportsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Reports" */ "pages/HospitalReports"
    )
);

const AsyncPatientsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Reports" */ "pages/Patients"
    )
);

const AsyncPatientDashboardPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Reports" */ "pages/PatientDashboard"
    )
);

const AsyncPatientInformationPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Reports" */ "pages/PatientInfo"
    )
);

const AsyncQuestionnairesPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Reports" */ "pages/Questionnaires"
    )
);
const AsyncUploadTomographyPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/UploadTomography"
    )
);
const AsyncMailPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Mail"
    )
);
const AsyncMonitoringPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Monitoring"
    )
);
const AsyncWellnessPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Wellness"
    )
);
const AsyncNutritionPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Nutrition"
    )
);
const AsyncAlertsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Alerts"
    )
);

const AsyncCompliancePage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Compliance"
    )
);

const AsyncUploadErrorsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/UploadErrors"
    )
);

const AsyncFaultDocumentationPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/FaultDocumentation"
    )
);

const AsyncPrivacyPolicyPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/Privacy"
    )
);

const AsyncRuleEnginePage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/RuleEngine"
    )
);

const AsyncRulesPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/RuleEngine/Rules"
    )
);

const AsyncGroupsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/RuleEngine/Groups"
    )
);

const AsyncRuleEngineLoginPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true,  webpackChunkName: "Databases" */ "pages/RuleEngine/Login"
    )
);

export interface RouterProps extends RouteComponentProps {
  isLoggedIn: boolean;
  user: UserDetails;
}

class Router extends Component<RouterProps, any> {
  loggedOutPage = (component) =>
    this.props.isLoggedIn
      ? () => {
          return <Redirect to={`/${this.props.location.search}`} />;
        }
      : component;

  ensureLoggedInAndPermission = (component, permission = null) => {
    // let isResearch = false;
    // window.location.href.split("/").forEach((urlPart) => {
    //   if (urlPart === "researchcenter") {
    //     isResearch = true;
    //     return;
    //   }
    // });

    if (
      this.props.user?.redirect_option === "True" ||
      this.props.user?.redirect_option === "true" ||
      this.props.user?.redirect_option === true
    ) {
      return;
    }

    if (!this.props.isLoggedIn) {
      return () => <Redirect to={"/login"} />;
    }

    if (
      permission &&
      !Permissions.haveUserPermission(this.props.user, permission)
    ) {
      return () => <Error403 />;
    }

    return () => <LoggedInPage Component={component} />;
  };

  ensureRuleEngine = (component) => {
    if (!this.props.isLoggedIn) {
      return () => <Redirect to={"/login"} />;
    }
    return () => <MainPage Component={component} />;
  };

  renderGeneralRoutes = () => {
    return [
      // add general routes here
    ];
  };

  renderGuestUserRoutes = () => {
    return [
      <Route
        key={ROUTES.RULE_ENGINE_LOGIN}
        path={ROUTES.RULE_ENGINE_LOGIN}
        component={this.loggedOutPage(AsyncRuleEngineLoginPage)}
      />,
      <Route
        key={ROUTES.LOGIN}
        path={ROUTES.LOGIN}
        component={this.loggedOutPage(AsyncLoginPage)}
      />,
      <Route
        key={ROUTES.FORGOT_PASSWORD}
        path={ROUTES.FORGOT_PASSWORD}
        component={this.loggedOutPage(AsyncResetPassword)}
      />,
      <Route
        key={`${ROUTES.SET_NEW_PASSWORD}/:uid/:token`}
        path={`${ROUTES.SET_NEW_PASSWORD}/:uid/:token`}
        component={this.loggedOutPage(AsyncActiveResetPassword)}
      />,
    ];
  };

  renderAuthenticatedUserRoutes = () => {
    return [
      <Route
        key={ROUTES.REPORTS}
        path={ROUTES.REPORTS}
        component={this.ensureLoggedInAndPermission(
          AsyncReportsPage,
          USER_PERMISSIONS.UPLOAD
        )}
      />,
      <Route
        key={ROUTES.TOMOGRAPHY}
        path={ROUTES.TOMOGRAPHY}
        component={this.ensureLoggedInAndPermission(
          AsyncUploadTomographyPage,
          USER_PERMISSIONS.UPLOAD
        )}
      />,
      <Route
        key={ROUTES.PATIENTS}
        path={ROUTES.PATIENTS}
        component={this.ensureLoggedInAndPermission(
          AsyncPatientsPage,
          USER_PERMISSIONS.MANAGE
        )}
      />,
      <Route
        key={ROUTES.DASHBOARD}
        path={`${ROUTES.DASHBOARD}/:patientId`}
        component={this.ensureLoggedInAndPermission(
          AsyncPatientDashboardPage,
          USER_PERMISSIONS.MANAGE
        )}
      />,
      <Route
        key={ROUTES.RESULTS}
        path={ROUTES.RESULTS}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncLabResultsPage,
          USER_PERMISSIONS.UPLOAD
        )}
      />,
      <Route
        key={ROUTES.UPLOAD_PARAMETERS}
        path={ROUTES.UPLOAD_PARAMETERS}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncUploadParametersPage,
          USER_PERMISSIONS.UPLOAD
        )}
      />,
      <Route
        key={ROUTES.UPLOAD_HOSPITAL_REPORTS}
        path={ROUTES.UPLOAD_HOSPITAL_REPORTS}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncUploadHospitalReportsPage,
          USER_PERMISSIONS.UPLOAD
        )}
      />,
      <Route
        key={ROUTES.VIEW_HOSPITAL_REPORTS}
        path={ROUTES.VIEW_HOSPITAL_REPORTS}
        component={this.ensureLoggedInAndPermission(
          AsyncHospitalReportsPage,
          USER_PERMISSIONS.UPLOAD
        )}
      />,
      <Route
        key={ROUTES.PATIENT_INFO}
        path={ROUTES.PATIENT_INFO}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncPatientInformationPage,
          USER_PERMISSIONS.UPLOAD
        )}
      />,
      <Route
        key={ROUTES.QUESTIONNAIRES}
        path={ROUTES.QUESTIONNAIRES}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncQuestionnairesPage,
          USER_PERMISSIONS.UPLOAD
        )}
      />,
      <Route
        key={ROUTES.MAIL}
        path={ROUTES.MAIL}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncMailPage,
          USER_PERMISSIONS.MANAGE
        )}
      />,
      <Route
        key={"/"}
        path={"/"}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncUploadDocumentsPage,
          USER_PERMISSIONS.UPLOAD
        )}
      />,
      <Route
        key={ROUTES.MONITORING}
        path={ROUTES.MONITORING}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncMonitoringPage,
          USER_PERMISSIONS.MANAGE
        )}
      />,
      <Route
        key={ROUTES.WELLNESS}
        path={ROUTES.WELLNESS}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncWellnessPage,
          USER_PERMISSIONS.MANAGE
        )}
      />,
      <Route
        key={ROUTES.NUTRITION}
        path={ROUTES.NUTRITION}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncNutritionPage,
          USER_PERMISSIONS.MANAGE
        )}
      />,
      <Route
        key={ROUTES.ALERTS}
        path={ROUTES.ALERTS}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncAlertsPage,
          USER_PERMISSIONS.MANAGE
        )}
      />,
      <Route
        key={ROUTES.COMPLIANCE}
        path={ROUTES.COMPLIANCE}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncCompliancePage,
          USER_PERMISSIONS.MANAGE
        )}
      />,
      <Route
        key={ROUTES.PRIVACY}
        path={ROUTES.PRIVACY}
        exact
        component={AsyncPrivacyPolicyPage}
      />,
      <Route
        key={ROUTES.UPLOAD_ERRORS}
        path={ROUTES.UPLOAD_ERRORS}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncUploadErrorsPage,
          USER_PERMISSIONS.MANAGE
        )}
      />,
      <Route
        key={ROUTES.FAULT_DOCUMENTATION}
        path={ROUTES.FAULT_DOCUMENTATION}
        exact
        component={this.ensureLoggedInAndPermission(
          AsyncFaultDocumentationPage,
          USER_PERMISSIONS.MANAGE
        )}
      />,
      <Route
        key={ROUTES.RULE_ENGINE}
        path={ROUTES.RULE_ENGINE}
        exact
        component={this.ensureRuleEngine(AsyncRuleEnginePage)}
      />,
      <Route
        key={ROUTES.RULE_ENGINE_RULES}
        path={ROUTES.RULE_ENGINE_RULES}
        exact
        component={this.ensureRuleEngine(AsyncRulesPage)}
      />,
      <Route
        key={ROUTES.RULE_ENGINE_GROUPS}
        path={ROUTES.RULE_ENGINE_GROUPS}
        exact
        component={this.ensureRuleEngine(AsyncGroupsPage)}
      />,
    ];
  };

  render() {
    return (
      <React.Suspense fallback={<LoadingOverlap />}>
        <Switch>
          {this.renderGeneralRoutes()}
          {this.renderGuestUserRoutes()}
          {this.renderAuthenticatedUserRoutes()}
          <Route path="*" render={() => <AsyncError404 />} />
        </Switch>
      </React.Suspense>
    );
  }
}
const mapStateToProps = (state: any, props: any) => {
  return {
    isLoggedIn: !!_.get(state, "auth.session.access_token"),
    user: _.get(state, "auth.userDetails", null),
  };
};

export default withRouter(connect(mapStateToProps, {})(withTracker(Router)));
