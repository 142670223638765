import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";
import { RouteComponentProps } from "react-router";
import UserBar from "components/MainSidebar/UserBar/UserBar";
import MenuItem from "components/MainSidebar/MenuItem/MenuItem";
import { ROUTES } from "businessLogic/routes.constants";
import styles from "./MainSidebar.module.scss";
import { Permissions } from "businessLogic/helpers/Permissions";

interface Props extends RouteComponentProps {
  session: any;
  userDetails: any;
}

export var userName = "";

const getUserName = (userData) => {
  userName = userData.username;
  return userData?.firstName && userData?.lastName
    ? userData.firstName + " " + userData.lastName
    : userData.username
    ? userData.username
    : userData.login_identity;
};

const MainSidebar: React.FunctionComponent<{} & Props> = ({
  match,
  session,
  userDetails,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.sidebarHead}>
        <div className={styles.userBarContainer}>
          {session && userDetails && (
            <UserBar
              email={userDetails.username}
              name={userDetails && getUserName(userDetails)}
            />
          )}
        </div>
        <div>
          {Permissions.canUpload(userDetails) && (
            <>
              <MenuItem
                text={"Patients Information"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.PATIENT_INFO}
                to={ROUTES.PATIENT_INFO}
              />
              <MenuItem
                text={"Upload Scan Documents"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.UPLOAD}
                to={ROUTES.UPLOAD}
              />
              <MenuItem
                text={"View Scanned Documents"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.REPORTS}
                to={ROUTES.REPORTS}
              />
              <MenuItem
                text={"Upload Tomography"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.TOMOGRAPHY}
                to={ROUTES.TOMOGRAPHY}
              />
              <MenuItem
                text={"Upload Lab Results"}
                selected={match.path === ROUTES.RESULTS}
                to={ROUTES.RESULTS}
              />
              <MenuItem
                text={"Upload Parameters"}
                selected={match.path === ROUTES.UPLOAD_PARAMETERS}
                to={ROUTES.UPLOAD_PARAMETERS}
              />
              <MenuItem
                text={"Upload Hospital Reports"}
                selected={match.path === ROUTES.UPLOAD_HOSPITAL_REPORTS}
                to={ROUTES.UPLOAD_HOSPITAL_REPORTS}
              />
              <MenuItem
                text={"View Hospital Reports"}
                selected={match.path === ROUTES.VIEW_HOSPITAL_REPORTS}
                to={ROUTES.VIEW_HOSPITAL_REPORTS}
              />
            </>
          )}
          {Permissions.canManage(userDetails) && (
            <>
              <MenuItem
                text={"Patients Scanned Data"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.PATIENTS}
                to={`${ROUTES.PATIENTS}/list`}
              />
              <MenuItem
                text={"PRO"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.QUESTIONNAIRES}
                to={ROUTES.QUESTIONNAIRES}
              />
              <MenuItem
                text={"Mail"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.MAIL}
                to={`${ROUTES.MAIL}`}
              />
              <MenuItem
                text={"Monitoring"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.MONITORING}
                to={`${ROUTES.MONITORING}`}
              />
              <MenuItem
                text={"Wellness"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.WELLNESS}
                to={`${ROUTES.WELLNESS}`}
              />
              <MenuItem
                text={"Nutrition"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.NUTRITION}
                to={`${ROUTES.NUTRITION}`}
              />
              <MenuItem
                text={"Alerts"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.ALERTS}
                to={`${ROUTES.ALERTS}`}
              />
              <MenuItem
                text={"Compliance"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.COMPLIANCE}
                to={`${ROUTES.COMPLIANCE}`}
              />
              <MenuItem
                text={"Upload Log"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.UPLOAD_ERRORS}
                to={`${ROUTES.UPLOAD_ERRORS}`}
              />
              <MenuItem
                text={"Fault Documentation"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.FAULT_DOCUMENTATION}
                to={`${ROUTES.FAULT_DOCUMENTATION}`}
              />
              <MenuItem text={""} disabled={true} />
              <hr></hr>
              <MenuItem
                text={"Research Center"}
                disabled={!(session && userDetails)}
                selected={match.path === ROUTES.RULE_ENGINE}
                to={`${ROUTES.RULE_ENGINE}`}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    session: _.get(state, "auth.session"),
    userDetails: _.get(state, "auth.userDetails"),
  };
};

export default connect(mapStateToProps, {})(withRouter(MainSidebar));
