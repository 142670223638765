export const ROUTES = {
  UPLOAD: "/",
  TOMOGRAPHY: "/tomography",
  REPORTS: "/reports",
  PATIENTS: "/patient",
  RESULTS: "/results",
  UPLOAD_PARAMETERS: "/upload-parameters",
  UPLOAD_HOSPITAL_REPORTS: "/upload-hospital-reports",
  VIEW_HOSPITAL_REPORTS: "/hospital-reports",
  LOGOUT: "/logout",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  SET_NEW_PASSWORD: "/reset_password",
  PATIENT_INFO: "/patient-info",
  QUESTIONNAIRES: "/questionnaires",
  DASHBOARD: "/dashboard",
  MAIL: "/mail",
  MONITORING: "/monitoring",
  WELLNESS: "/wellness",
  NUTRITION: "/nutrition",
  ALERTS: "/alerts",
  COMPLIANCE: "/compliance",
  UPLOAD_ERRORS: "/upload-log",
  FAULT_DOCUMENTATION: "/fault-documentation",
  PRIVACY: "/privacy-policy",
  RULE_ENGINE: "/researchcenter",
  RULE_ENGINE_GROUPS: "/researchcenter/groups",
  RULE_ENGINE_RULES: "/researchcenter/rules",
  RULE_ENGINE_LOGIN: "/researchcenter/login",
};
